.Comment {
    border-radius: 8px;
    background-color: #1b262c;
    padding: 10px;
    margin-top: 20pt;
    margin-left: 20pt;
    margin-right: 20pt;
}

.Comment-title { 
  font-size: 20pt;
  font-weight: bold;
}

.Comment-date {
    color: #ff865b;
    font-size: 10pt;
}
.Comment-reply-button {
}
.Comment-reply-text {
    color: #ff865b;
    text-align: center;
    text-decoration: underline;
}

.Comment-submit-button {
    padding-top: 5pt;
}

.Comment-error {
    border-radius: 8px;
    background-color: #ff865b;
    padding: 10px;
    margin-bottom: 20pt;
    color: #160603;
}
