@tailwind base;
@tailwind components;
@tailwind utilities;

@tailwind "tailwindcss/base";
@tailwind "tailwindcss/components";
@tailwind "tailwindcss/utilities";

body {
    font-family: "DM Mono";
}
