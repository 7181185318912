
.App {
text-align: center;
}

.badge {
    width:50%;
    background-color: #00000000;
    height: auto;
    align-self: center;
}
.badge-holder {
    display: flex;
    margin: 20pt;
    padding: 5px;
    flex-direction: column;
    justify-content: center;
    
}

.Comments-reply {
    font-size: 24px;
    margin: 20pt;
}

.Comments-description {
    font-size: 12pt;
    padding-top: 5pt;
    padding-bottom: 5pt;
}

.Comments-button {
    float: right;
}
