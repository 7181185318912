.Child-comment {
    padding: 10pt;
    border-radius: 8px;
    margin-left: 45pt;
    margin-right: 20pt;
    margin-top: 5pt;
    background-color: #1b262c;
}

.Child-comment-content {

}

.Child-comment-date {
    font-size: 8pt;
}
